<template>
    <div class="cmcad-list">
        <div class="background"></div>
        <div class="flex x-between container top-content">
            <router-link :to="{ path: '/' }" class="return-index t-c fs14 ml20 mt30 mb50">返回首页</router-link>
            <div class="login-btn mt40" v-if="!(userInfo || {}).Mobile">
                <a class="color333 fs14" href="javascript:;" @click="handleLoginEvent">登录</a>
                <span class="pl5 pr5">|</span>
                <a class="color333 fs14" href="javascript:;" @click="handleRegisterEvent">注册</a>
            </div>
            <div class="mt40" v-else>
                <a-dropdown placement="bottomCenter">
                    <a style="height:32px;" class="flex y-center ant-dropdown-link" @click="e => e.preventDefault()">
                        <a-avatar icon="user" :src="userInfo.Avatar || require('@/assets/images/person-default.png')" />
                        <span class="ml10 color666 fs16">{{ userInfo.RealName }}</span>
                        <a-icon class="color333 ml10" type="down" />
                    </a>
                    <a-menu slot="overlay" style="max-width:100px;">
                        <a-menu-item class="t-c">
                            <a target="_blank" :href="personal_url + committee_id">个人中心</a>
                        </a-menu-item>
                        <a-menu-item class="t-c">
                            <a target="_blank" :href="mini_url + 'user/index/' + committee_id">管理中心</a>
                        </a-menu-item>
                        <a-menu-item class="t-c">
                            <a @click="handlelogoutEvent" href="javascript:;">安全退出</a>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>

            </div>
        </div>
        <div class="container mb20">
            <img style="width:100%;" src="~@/assets/images/paper-enter_1.jpg" alt=""></img>
        </div>
        <div class="container content pt10 pb10 pl10 pr10">
            <filter-tab title="学科" :list="subjectList" :isShowMore="true" @clickItem="handleFilterEvent('subjectIndex', $event)"></filter-tab>
            <div class="flex x-between fs14 mt30 pb10 mobile-search">
                <div class="mt5">
                    <span class="pb15 strong" style="padding:3px 14px;display: block;width: 85px;">检索条件</span>
                </div>
                <div class="flex-item flex x-left search-condition">
                    <div class="search-condition-inner">
                        <div class="flex x-left mb10 select-box" v-for="(itemSearch, itemIndex) in searchList"
                            :key="itemIndex">
                            <div class="flex x-left select-box-inner">
                                <a-select :default-value="andList[0].name" v-if="itemIndex > 0" placeholder="类型"
                                    style="width:120px;" class="mr10" @change="handleChangeTypes($event, itemIndex)">
                                    <a-select-option :selected="item.selected" v-for="(item, index) in andList"
                                        :value="item.name" :key="index">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                                <a-select :default-value="typeList[0].name" placeholder="类型" style="width:120px;"
                                    @change="handleChangeType($event, itemIndex)">
                                    <a-select-option :selected="item.selected" v-for="(item, index) in typeList"
                                        :value="item.id" :key="index">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                                <div style="position:relative;width:800px;" class="more-select ml10">
                                    <a-input placeholder="请输入" class="input-long"
                                        @change="handleInputChange($event, itemIndex)" />
                                    <div v-if="itemIndex == 0 && showTip" style="color:red;" class="mt50 t-l">请输入要检索的内容
                                    </div>
                                </div>
                                <div class="ml20 fs28 color999 cursor" v-if="itemIndex > 0"
                                    @click="delSearchCondition(itemIndex)">-</div>
                                <div class="ml20 fs28 color999 cursor"
                                    v-if="(itemIndex == searchList.length - 1) && itemIndex < 4"
                                    @click="addSearchCondition">+</div>

                            </div>

                        </div>
                        <div style="width:100%;" class="mt10">
                            <a-button class="btn-search fs16 colorfff strong t-c" type="primary" @click="onSubmit">
                                检索
                            </a-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container content pt30 pb20 pl20 pr20 mt20">
            <div class="t-l">
                <div class="color999 fs14 t-l mb20">会务通帮您找到相关内容 {{ databaseTotal }}篇</div>
                <a-spin :spinning="loading" tip="Loading...">
                    <div class="interview-list" v-if="databaseList.length">
                        <div v-for="(item, index) in databaseList" :key="index" class="database-list pb20 t-l"
                            :class="{ 'mt20': index != 0 }" @click="goArticleDetail(item.id, item.abstract_id)">
                            <p class="fs16 color333 strong mb10" v-html="item.title || item.title_en"></p>
                            <div class="flex x-between color999 fs14 mb10">
                                <div class="row-1">
                                    <span v-if="item.real_name" class="color333 mr10">作者：{{ item.real_name }}</span>
                                    <span v-if="item.one_org_cnname">{{ item.one_org_cnname }}</span>
                                </div>
                                <!-- <div class="ml20"><i class="med med-yanjing fs14 mr5"></i>{{item.hits}}</div> -->
                            </div>
                            <p v-if="item.keywords" class="fs14 color333 mb10">关键词：{{ item.keywords }}</p>
                            <div class="flex x-between color999 fs14 mb10">
                                <div class="row-1">
                                    <span v-if="item.cn_title" class="fs14 color333">来源：<span>{{ item.cn_title
                                    }}</span></span>
                                </div>
                            </div>
                            <div><i class="med med-yanjing fs14 mr5"></i>{{ item.hits }}</div>
                        </div>
                    </div>
                    <a-empty style="margin-top:100px;" v-if="!databaseList.length && !loading" />
                </a-spin>
                <div class="t-c mt30" v-if="databaseTotal > 0">
                    <a-pagination :total="databaseTotal" v-model:current="databaseParams.page"
                        :page-size="databaseParams.limit" show-quick-jumper @change="getPaperList"
                        :hideOnSinglePage="true" />
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
 
<script>
import Footer from "@/components/layout/layout-footer";
import layoutRight from '@/components/layout/layout-right';
import commonTitle from '@/components/common-title';
import filterTab from '@/components/filter-tab';
import Header from '@/components/layout/layout-header';
import { jsonFormat } from '@/utils/jsonFormat';
import { mapState } from 'vuex';
import { mini_url, committee_id,personal_url } from '@/config';
import storage from 'store'
export default {
    name: "AdvancedSearch",
    components: {
        Footer, layoutRight, commonTitle, filterTab, Header
    },
    data() {
        return {
            personal_url,
            mini_url,
            committee_id,
            searchName: '',
            logoUrl: 'images/logo.png',
            navList: [],//顶部菜单
            navCurrentId: '',//当前页面菜单对应下标
            showTip: false,
            typeList: [
                { id: 'title', name: '标题', selected: true },
                { id: 'one_real_name', name: '第一作者', selected: false },
                { id: 'one_org_cnname', name: '第一作者单位', selected: false },
                { id: 'all_name', name: '所有作者', selected: false },
                { id: 'keywords', name: '关键词', selected: false },
                { id: 'content', name: '内容', selected: false },
                { id: 'cn_title', name: '来源', selected: false },
            ],
            andList: [
                { name: 'and', selected: true },
                { name: 'or', selected: false },
                { name: 'not', selected: false },
            ],
            searchList: [
                ['and', 'title', ''],
            ],
            likeIndex: -1,
            likeNumList: [
                { id: 1, name: '500以下' },
                { id: 2, name: '500~1000' },
                { id: 3, name: '1000~5000' },
                { id: 4, name: '5000以上' },
            ],
            databaseTotal: 0,
            databaseParams: {
                page: 1,
                limit: 5,
                // search_auto: {},
            },
            databaseList: [],
            loading: true,
            subjectList: [],
            subjectIndex: -1,
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    created() {
        this.getSubjectList()
        this.getPaperList()
    },

    methods: {
        // 添加检索条件
        addSearchCondition() {
            let a = ['and', 'title', '']
            this.searchList.push(a)
        },
        // 删除检索条件
        delSearchCondition(index) {
            this.searchList.splice(index, 1)
            if(this.searchList.length == 1){
                this.showTip = false
            }
        },
        // 登录
        handleLoginEvent() {
            window.location.href = this.mini_url + "unified/login?return_url=" + encodeURIComponent(window.location.href);
            console.log(window.location.href)
        },
        // 注册
        handleRegisterEvent() {
            window.location.href = this.mini_url + "unified/register?return_url=" + encodeURIComponent(window.location.href);
        },
        // 退出登录
        handlelogoutEvent() {
            storage.set('auth', '');
            storage.set('showSelected', false)
            storage.remove('subjectIds')
            storage.remove('operate_id')
            //this.$store.commit('updateState',{userInfo:null});
            window.location.href = mini_url+'site/logout/'+committee_id+'?return_url='+window.location.origin
        },
        // and or not选择器
        handleChangeTypes(e, index) {
            this.searchList[index][0] = e
        },
        // 检索内容选择
        handleChangeType(e, index) {
            this.searchList[index][1] = e
        },
        // 检索内容输入
        handleInputChange(e, index) {
            this.searchList[index][2] = e.target.value
        },
        onSubmit(e) {
            this.databaseParams.page = 1
            let searchList = []
            this.searchList.forEach((e, i) => {
                searchList.push(e)
            })
            for (let i = 0; i < searchList.length; i++) {
                if (i == 0 && searchList.length > 1) {
                    if (!searchList[0][2]) {
                        this.showTip = true
                        return false
                    } else {
                        this.showTip = false
                    }
                }
            }
            if ((searchList[0] && searchList[0][1] != 'operate_id' && this.operated_id) || (!searchList.length && this.operated_id)) {
                searchList.unshift(['and', 'operate_id', this.operated_id],)
            }
            if (searchList.length) {
                let obj = {
                    "search": searchList
                }
                this.databaseParams.search_auto = JSON.stringify(obj)
            } else {
                delete this.databaseParams.search_auto
            }
            this.getPaperList()
        },

        getPaperList() {
            this.loading = true
            this.request.post('AbstractSearch', this.databaseParams).then(res => {
                this.databaseTotal = parseInt(res.data.count)
                let databaseList = res.data.list
                this.databaseList = databaseList
                this.loading = false
            })
        },
        getSubjectList() {
            this.$store.dispatch('getSubjectList').then(res => {
                let subjectObj = {
                    name: 'operate_name',
                }
                this.subjectList = jsonFormat(res.data, subjectObj)
            })
        },
        handleFilterEvent(key, e) {
            key && (this[key] = e);
            if (this.subjectIndex != -1) {
                this.operated_id = this.subjectList[this.subjectIndex].id
            } else {
                this.operated_id = ''
                // this.getPaperList()
            }
        },
        goArticleDetail(id, classId) {
            this.$router.push({
                path: '/database/database-detail',
                query: {
                    id,
                    classId,
                    meeting_id: id
                }
            })
        }
    }
}
</script>
 
<style scoped lang="less">
.cmcad-list {
    .background {
        width: 100%;
        height: 408px;
        background: linear-gradient(360deg, rgba(239, 235, 255, 0) 0%, #EEEAFF 100%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .login-btn {
        height: 20px;
    }

    .return-index {
        width: 92px;
        height: 40px;
        border: 1px solid #1E51C9;
        border-radius: 19px;
        line-height: 38px;
        color: #1E51C9;
        cursor: pointer;
        display: block;
    }

    .cmcad-top {
        /deep/.ant-input-search {
            background: #ffffff;
            border-radius: 100px;
        }

        /deep/.ant-input,
        /deep/.ant-btn {
            height: 40px;
        }
    }

    .content {
        width: 1240px;
        border-radius: 10px;
        height: auto;
        background: #ffffff;

        .filter-box {
            border-bottom: 0;
        }

        .database-list {
            border-bottom: 1px dashed #D8D8D8;
            cursor: pointer;
        }

        /deep/.ant-spin {
            width: 100%;
        }

        .content-right {
            width: 398px;
        }

        .input-long {
            width: 100%;
            height: 44px;
            position: absolute;
            right: 0;
        }

        /deep/.ant-select-selection,
        /deep/.ant-select-selection__rendered {
            height: 44px;
        }

        /deep/.ant-select-selection-selected-value {
            line-height: 44px;
        }

        /deep/.ant-form-item {
            float: left;
            margin-bottom: 10px;
        }

        .btn-search {
            width: 124px;
            height: 48px;
            line-height: 48px;
            background: linear-gradient(to right, #5F96E6, #1E51C9);
            border-radius: 6px;
        }

        .more-select {

            /deep/.ant-select-selection {
                background: #CCCCCC;
                color: #ffffff;
            }

            /deep/.ant-select-arrow-icon,
            /deep/.ant-select-selection__placeholder {
                color: #ffffff;
            }
        }

        .select-box:nth-child(1) {
            .more-select {
                width: 930px !important;
            }
        }

    }

    .cursor {
        cursor: pointer;
    }
}
@media screen and (max-width:768px){
    .cmcad-list{
        width: 100%;
        margin-top: -80px;
        .container{
            padding: 10px;
            margin-bottom: -20px;
            .mt40{
                margin-top: 35px;
            }
            .return-index{
                margin-left: 0;
            }
        }
        .top-content{
            display: flex;
        }
        .content{
            width: 100%;
            .filter-box{
                margin-left: -14px;
            }
            .mobile-search{
                display: block;
                margin-top: 10px;
                .mt5{
                    margin-left: -14px;
                }
            }
            .search-condition{
                width: 100%;
                &-inner{
                    width: 100%;
                    .select-box-inner{
                        width: 100%;
                        .ant-select{
                            // width: 10%!important;
                        }
                    }
                }
            }
        }
    }
}
</style>